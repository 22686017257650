const ContactPage: React.FC = () => {
    const copySoupEmail = () => {
        navigator.clipboard.writeText("info@polevky-hribecek.fun");
        alert("E-mail byl zkopírován do schránky.")
    }

    const copySoupNumber = () => {
        navigator.clipboard.writeText("+420910122904");
        alert("Telefonní číslo bylo zkopírováno do schránky.")
    }
    
  return (
    <div className="contact-page">
      <h1 className="contact-title">Hříbečkovy polévky - Kontakt</h1>
      <p className="contact-description">
        Máte otázky, zájem o naše produkty, nebo nám chcete sdělit zpětnou vazbu? Rádi vás uslyšíme!
      </p>
      <div className="contact-details">
        <h2>Kontaktujte nás</h2>
        <p><strong>Adresa:</strong> Hlavní 369, 100 00 Polévkov</p>
        <p><strong>Telefon:</strong> <button onClick={copySoupNumber}>+420 910 122 904</button></p>
        <p><strong>E-mail:</strong> <button onClick={copySoupEmail}>info@polevky-hribecek.fun</button></p>
      </div>
    </div>
  );
};

export default ContactPage;
