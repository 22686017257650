import { useSearchParams } from "react-router-dom";
import { soups } from "../assets/soups";

function ProductPage() {

    const [searchParams, setSearchParams] = useSearchParams();

    return ( 
        <div className="productPage">
            <div className="container">
                <img src={soups[Number(searchParams.get("id"))].img} alt="img polévky"/>
                <div className="desc">
                    <h1>{soups[Number(searchParams.get("id"))].title}</h1>
                    <p>{soups[Number(searchParams.get("id"))].desc}</p>
                    <br/>
                    <div className="contents">
                        <p>Složení:</p>
                        <p>{soups[Number(searchParams.get("id"))].contents}</p>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default ProductPage;