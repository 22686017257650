import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  return (
    <footer>
      {location.pathname === "/smoula" ? (
        <div>
            <p>&copy; 2024 ŠMouLa</p>
        </div>
      ) : (
        <div>
            <p>&copy; 2024 Hříbečkovy polévky s.r.o.</p>
            <p><Link to="/smoula">O projektu</Link></p>
        </div>
      )}
    </footer>
  );
};

export default Footer;