function AboutUsPage() {
  return (
    <div className="about-us-page">
      <h1 className="about-us-title">O nás</h1>
      <section className="about-us-intro">
        <p>
          Vítejte u <strong>Hříbečkových polévek</strong>, kde vášně pro kvalitní jídlo ožívají v každé lžíci. Jsme malá rodinná výrobna, která se specializuje na výrobu čerstvých a lahodných polévek připravených z těch nejlepších surovin.
        </p>
      </section>
      <section className="about-us-story">
        <h2>Naše historie</h2>
        <p>
          Náš příběh začal v roce 1936, kdy Dědeček Hříbeček nejstarší uvařil svou první polévku. Od té doby šíříme svou lásku ke tradiční české kuchyni. Vybudovali jsme podnik, který kombinuje tradici, moderní přístup a především péči o každého zákazníka.
        </p>
      </section>
      <section className="about-us-values">
        <h2>Naše hodnoty</h2>
        <ul>
          <li><strong>Kvalita:</strong> Používáme pouze čerstvé a lokální suroviny.</li>
          <li><strong>Tradice:</strong> Respektujeme osvědčené receptury a postupy.</li>
          <li><strong>Udržitelnost:</strong> Dbáme na ekologické balení a minimalizaci odpadu.</li>
        </ul>
      </section>
      <section className="about-us-mission">
        <h2>Naše mise</h2>
        <p>
          Naší misí je přinášet na váš stůl polévky, které vás zahřejí, potěší a spojí s vašimi blízkými. Proto věříme, že každá polévka má svůj příběh – a ten náš začíná u vás.
        </p>
      </section>
    </div>
  );
};

export default AboutUsPage;
