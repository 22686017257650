import { Link } from "react-router-dom";
import { soups } from "../assets/soups";

function Homepage() {

    const mapSoups = () => {
        let i = -1;

        return soups.map(s => {i+=1; return <div className="product">
                                                <img src={s.img}/>
                                                <h3>{s.title}</h3>
                                                <p>{s.tag}</p>
                                                <Link to={`/polevka?id=${i}`}><button>Více informací</button></Link>
                                            </div>})
    }

    return ( 
    <>

    <section id="domu" className="hero">
        
        <video autoPlay muted loop>
            <source src={require("../assets/bgvid.mp4")} type="video/mp4"/>
        </video>

        <div className="hero-content">
            <h1>Ochutnejte magii českých lesů a hájů</h1>
            <p>Vydatné houbové polévky bez zašpinění Vašich holínek</p>
            {/*<a href="#produkty" className="button">Naše polévky</a>
            <a href="#onas" className="button-secondary">Learn More</a>*/}
        </div>
    </section>

    <section id="onas">
        <h2>O nás</h2>
        <br/>
        <h3>Tradice a láska k českým lesům</h3>
        <p>Hříbečkovy polévky s.r.o. vznikly z hluboké úcty k přírodě a bohatým lesům České republiky. Zakladatelé firmy, inspirováni tradicí sběru hub, se rozhodli vytvořit produkty, které by přenesly kouzlo českých lesů do každého talíře. Naše polévky jsou založeny na recepturách předávaných z generace na generaci, obohacených o moderní postupy, které zachovávají přírodní chuť a nutriční hodnoty každé suroviny.</p>
        <br/><br/>
        <h3>Přírodní a lokální suroviny</h3>
        <p>Všechny polévky od Hříbečkových polévek jsou vyráběny z lokálně sbíraných a pečlivě vybraných hub a zeleniny. Základem je důraz na udržitelnost - spolupracujeme s místními sběrači a farmáři, kteří sdílí náš závazek k ekologii.</p>
        <br/><br/>
        <h3>Spojení tradice a moderního životního stylu</h3>
        <p>Naše polévky jsou navrženy tak, aby odpovídaly potřebám dnešních zákazníků, kteří hledají zdravé, rychlé a chutné možnosti pro každodenní stravování. Díky praktickému balení a široké škále variant, od tradičních krémových polévek po inovativní veganské recepty, si každý najde tu svou. Hříbečkovy polévky přináší harmonii tradice a moderní gastronomie, aby každý doušek byl oslavou přírody.</p>
    </section>

    <section id="produkty">
        <h2>Naše polévky</h2>
        <h4>Vyberte si z naší pestré nabídky mňamózních polévek</h4>
        <div className="product-grid">
            {mapSoups()}
        </div>
    </section>
    </>
     );
}

export default Homepage;