import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Homepage from "./pages/Homepage";

import "./App.css"
import ProductPage from "./pages/ProductPage";
import CompPage from "./pages/CompPage";
import ContactPage from "./pages/ContactPage";
import AboutUsPage from "./pages/AboutUsPage";
import Footer from "./components/Footer";

function App() {
  return (
    <BrowserRouter>

      <header>
            <div className="logo">
              <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
                Hříbečkovy polévky
              </Link>
            </div>
            <nav>
                <ul>
                    <Link to="/o-nas" style={{ textDecoration: 'none', color: 'white' }}><li>O nás</li></Link>
                    <Link to="/kontakt" style={{ textDecoration: 'none', color: 'white' }}><li>Kontakt</li></Link>
                </ul>
            </nav>
      </header>

      <Routes>
        <Route path="/" element={<Homepage/>}/>
        <Route path="/polevka" element={<ProductPage/>}/>
        <Route path="/o-nas" element={<AboutUsPage/>}/>
        <Route path="/kontakt" element={<ContactPage/>}/>
        <Route path="/smoula" element={<CompPage/>}/>
      </Routes>

      <Footer/>

    </BrowserRouter>
  );
}

export default App;
